import React from "react"
import { Container, Row, Col } from "reactstrap";
import { Link } from "gatsby";

import SEO from "../components/seo";
import Layout from "../components/Layout/Layout.component";

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => browser && (
  <Layout
    metaTitle="404 | Gallo Salame®"
    metaDescription="Oops! Page not found"
  >
    <section className="gagl-404-wrapper">
      <Container >
        <Row>
          <Col className="gagl-404-header-container">

            <Container>
              <h1 className="gagl-404-header-text">WE CAN'T FIND THAT PAGE</h1>
              <p className="gagl-404-para-text">
                Our site is functional but clearly this link isn’t. Want to
                try again? Check out{" "}
                <Link to={"/our-products/"} className="">
                  our products
                </Link>{" "},
                <Link to={"/recipes/"} className="">
                  &nbsp; our recipes
                </Link>{" "}
                or see{" "}
                <Link to={"/where-to-buy/"} className="">
                  where to buy
                </Link>{" "}
                your favorite Galileo<sup>®</sup> Salame products.
              </p>

            </Container>
            {/* <Container tag={"section"} className="gagl-404-links-section pb-2">

              <h2 className="gagl-404-para-text mb-2 text-left">
                Or checkout the following pages..
              </h2>
              <Link to={"/"}>Home page</Link>
              <Link to={"/our-story/"}>Our Story Page</Link>
              <Link to={"/our-products/"}>Our Products Page</Link>
              <Link to={"/recipes/"}>Recipes Page</Link>
              <Link to={"/faqs/"}>Faqs</Link>
              <Link to={"/contact-us/"}>Contact us Page</Link>
            </Container> */}
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default NotFoundPage
